import React from "react";
import CarouselButton from "../CarouselButton";
import { CarouselSlide } from "./CarouselSlide";
import styled from "styled-components";
import { Flex, Image } from "@mantine/core";
import { useState } from "react";
import ethIcon from "../../assets/ETH.svg";
import usdcIcon from "../../assets/USDC.svg";
import usdtIcon from "../../assets/USDT.svg";
import daiIcon from "../../assets/DAI.svg";
import axios from "axios";
import { FAUCET_CONTRACT_ADDRESS } from "../../constants/address";
import faucetABI from "../../abi/faucet.json";
import guideImage1 from "../../assets/guide-image-14.png";
import guideImage2 from "../../assets/guide-image-16.png";
import guideImage3 from "../../assets/guide-image-17.png";
import arrowIcon from "../../assets/Arrow.svg";
import shiftBtn from "../../assets/shift-btn.svg";
import cmdBtn from "../../assets/command-btn.svg";
import eBtn from "../../assets/e-btn.svg";
import altBtn from "../../assets/alt-btn.svg";
import {
  useAccount,
  useNetwork,
  usePublicClient,
  useSwitchNetwork,
  useWalletClient,
} from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { lineaTestnet } from "viem/chains";
import { TransactionExecutionError } from "viem";

interface Props {
  currSlide?: number;
}
export default function GuideCarouselDetail4({ currSlide }: Props) {
  const [loadingFaucetAPI, setLoadingFaucetAPI] = useState(false);
  const [loadingConnect, setLoadingConnect] = useState(false);
  const [watingTx, setWatingTx] = useState(false);

  const { isConnected: isActive, address: account } = useAccount();
  const { open } = useWeb3Modal();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const isLinea = chain?.id === 59140;

  return (
    <Wrapper
      bg={"#F3D6D6"}
      mah={currSlide === 3 ? undefined : currSlide === 4 ? 387.46 : 312}
    >
      <Flex className="how-to-receive-token">How to Receive Tokens</Flex>
      <Flex gap={12}>
        <Image src={ethIcon} width={42} />
        <Image src={usdcIcon} width={42} />
        <Image src={usdtIcon} width={42} />
        <Image src={daiIcon} width={42} />
      </Flex>
      <CarouselButton
        loading={loadingFaucetAPI || loadingConnect}
        onClick={(e) => {
          if (!isActive) {
            setLoadingConnect(true);
            (async () => {
              try {
                await open();
              } catch (e) {
                localStorage.clear();
                console.error(e);
              } finally {
                setLoadingConnect(false);
              }
            })();
          } else {
            if (!isLinea) {
              alert("Switch chain first");
              switchNetwork();
              return;
            }
            setLoadingFaucetAPI(true);
            (async () => {
              try {
                const res = await axios.post(
                  `${process.env.REACT_APP_FLUVI_API_URL}/api/faucet/${account}`
                );
                const { data, adminSignature } = res.data;

                setWatingTx(true);

                try {
                  await publicClient.simulateContract({
                    address: FAUCET_CONTRACT_ADDRESS,
                    abi: faucetABI,
                    functionName: "claim",
                    args: [data, adminSignature],
                    chain: lineaTestnet,
                  });
                } catch (e) {
                  if (!e.message?.includes("transfer to the zero address")) {
                    throw e;
                  }
                }

                const result = await walletClient.writeContract({
                  address: FAUCET_CONTRACT_ADDRESS,
                  abi: faucetABI,
                  functionName: "claim",
                  args: [data, adminSignature],
                  chain: lineaTestnet,
                });
                console.log(result);
                alert("Done!");
              } catch (e) {
                if (e.response?.status === 429) {
                  alert("Too many requests");
                } else if (e.message.includes("already claimed")) {
                  alert("Already claimed!");
                } else if (e.response?.status === 400) {
                  alert(`Invalid address ${account}`);
                } else if (!!e.response?.status) {
                  alert("Oops, something went wrong");
                } else {
                  if (e instanceof TransactionExecutionError) {
                    alert("An unknown RPC error occurred.");
                  } else {
                    alert("Transaction failed");
                  }
                }
                console.error(e);
              } finally {
                setLoadingFaucetAPI(false);
                setWatingTx(false);
              }
            })();
          }
        }}
      >
        {isActive
          ? !isLinea
            ? "Switch Network"
            : watingTx
            ? "Claim with Fluvi Wallet"
            : "Receive LineaETH, Tokens"
          : "Connect Wallet First"}
      </CarouselButton>
      <Image
        style={{ transform: "rotate(90deg)" }}
        src={arrowIcon}
        width={32}
      />
      <Flex
        className="guide-1-desc"
        direction={"column"}
        align={"center"}
        gap={12}
      >
        <Image src={guideImage1} width={330} />
        Notifications will appear on the Fluvi Wallet icon.
      </Flex>
      <Flex className="short-cut-guide" direction={"column"} gap={8}>
        <Flex>Open Fluvi Wallet with Shortcut</Flex>
        <Flex gap={8} align={"center"}>
          <Image src={shiftBtn} width={42} />
          +
          <Image src={cmdBtn} width={20} />
          +
          <Image src={eBtn} width={20} />
          (MAC)
        </Flex>
        <Flex gap={8} align={"center"}>
          <Image src={shiftBtn} width={42} />
          +
          <Image src={altBtn} width={27} />
          +
          <Image src={eBtn} width={20} />
          (Windows)
        </Flex>
      </Flex>
      <Flex
        className="guide-1-desc"
        direction={"column"}
        align={"center"}
        gap={12}
      >
        <Image src={guideImage2} width={330} />
        on Notification Tab, Click Contract Interaction.{" "}
      </Flex>
      <Flex
        className="guide-1-desc"
        direction={"column"}
        align={"center"}
        gap={12}
      >
        <Image src={guideImage3} width={330} />
        Click 'Send Now' and verify the 2FA code sent to you by email.
      </Flex>
    </Wrapper>
  );
}

const Wrapper = styled(CarouselSlide)`
  gap: 24px;
  padding: 48px 62px 120px;

  .how-to-receive-token {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-family: Satoshi;
    font-weight: 700;
    line-height: 33.6px;
  }
  .guide-1-desc {
    color: #000;
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
    line-height: 140%;
    text-align: center;
  }
  .short-cut-guide {
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
    line-height: 14px;
    color: #000;
    padding: 12px;
    border-radius: 12px;
    background: rgba(236, 236, 236, 0.7);
    width: 100%;
    max-width: 330px;
  }
`;
