import React, { useState } from "react";
import { CarouselSlide } from "./CarouselSlide";
import styled from "styled-components";
import { Flex, Image } from "@mantine/core";
import { AlertAlreadyMapped } from "../AlertAlreadyMapped";
import { WagmiConnectorCard } from "../WagmiConnectorCard";
import { SignCard } from "../SignCard";
import { Progress, ProgressModal } from "../ProgressModal";
import arrowCircleDown from "../../assets/ArrowCircleDown.png";
// import { useIsDeployed } from "../../hooks/useIsDeployed";
import { useAlreadyMapped } from "../../hooks/useAlreadyMapped";
import { W3RConnectorCard } from "../W3RConnectorCard";
import { useAccount } from "wagmi";
import { getConnectorHooksByName } from "../../connectors";

interface Props {
  currSlide?: number;
}
export default function GuideCarouselDetail6({ currSlide }: Props) {
  const [progress, setProgress] = useState(Progress.DEFAULT);
  const [error, setError] = useState();
  const { address: account } = useAccount();
  const mmHooks = getConnectorHooksByName("MetaMask");
  const mmAccount = mmHooks.useAccount();

  const { mappedAccount: isFluviAddressMapped, refetch } = useAlreadyMapped({
    from: "fluvi",
    fromAccount: account,
  });
  const { mappedAccount: isMMAddressMapped, refetch: refetchMM } =
    useAlreadyMapped({
      from: "metamask",
      fromAccount: mmAccount,
    });
  // const { isDeployed, loading } = useIsDeployed(account);

  const sameConnected =
    mmAccount && account && mmAccount?.toLowerCase() === account?.toLowerCase();

  return (
    <Wrapper
      bg={"linear-gradient(180deg, #4DBF81 0%, #9999F1 52.08%, #FB8E2C 100%)"}
      mah={currSlide === 5 ? undefined : currSlide === 4 ? 387.46 : 312}
      p={"24px 24px 96px 24px"}
    >
      <Flex gap={24} direction={"column"} align={"center"}>
        <Flex direction={"column"} className="almost-done-desc">
          <b style={{ fontSize: 24, lineHeight: "34px" }}>Almost done!</b>
          To participate in Linea Voyage on Galxe, connect and submit your
          MetaMask and Fluvi Wallet. Please note, a single Fluvi Wallet can only
          be linked with one MetaMask wallet address.
        </Flex>
        <Flex className={"contents"}>
          <AlertAlreadyMapped
            from={"metamask"}
            isDeployed
            mappedAccount={isMMAddressMapped}
            fromAccount={mmAccount}
          />
          {isMMAddressMapped?.toLowerCase() !== account?.toLowerCase() && (
            <AlertAlreadyMapped
              from={"fluvi"}
              isDeployed
              sameConnected={sameConnected}
              mappedAccount={isFluviAddressMapped}
              fromAccount={account}
            />
          )}

          <W3RConnectorCard
            title={"Connect MetaMask"}
            connectorName={"MetaMask"}
          />
          <Image src={arrowCircleDown} width={32} />
          <WagmiConnectorCard
            title={"Connect Fluvi Wallet"}
            connectorName={"WalletConnectV2"}
          />
          <Image src={arrowCircleDown} width={32} />
          <SignCard
            setProgress={setProgress}
            setError={setError}
            disabled={
              !!isFluviAddressMapped || sameConnected /*|| !isDeployed*/
            }
            // loading={loading}
            onSuccess={() => {
              refetch();
              refetchMM();
            }}
          />
          {/* <Flex className="not-deployed-msg">
            You must have made at least one transaction using Fluvi Wallet.
          </Flex> */}
          <ProgressModal
            progress={progress}
            error={error}
            onClose={() => {
              setProgress(Progress.DEFAULT);
              setError(undefined);
            }}
          />
        </Flex>
      </Flex>
    </Wrapper>
  );
}

const Wrapper = styled(CarouselSlide)`
  .almost-done-desc {
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #000000;
    gap: 8px;
  }
  .contents {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    max-width: 389px;

    .not-deployed-msg {
      margin-top: 12px;
      font-family: "Inter";
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      color: #000000;
    }
  }
`;
