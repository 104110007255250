import type { Web3ReactHooks } from "@web3-react/core";
import { Text } from "@mantine/core";
import styled from "styled-components";

export function Status({
  isActivating,
  isActive,
  error,
}: {
  isActivating: ReturnType<Web3ReactHooks["useIsActivating"]>;
  isActive: ReturnType<Web3ReactHooks["useIsActive"]>;
  error?: Error;
}) {
  return (
    <Wrapper>
      {error ? (
        <Text className="dot-status">
          <Dot color={"red"} />
          {/* {error.name ?? "Error"}
          {error.message ? `: ${error.message}` : null} */}
          Oops, something went wrong
        </Text>
      ) : isActivating ? (
        <Text className="dot-status">
          <Dot color={"yellow"} /> Connecting
        </Text>
      ) : isActive ? (
        <Text className="dot-status">
          <Dot color={"#4ADE80"} /> Connected
        </Text>
      ) : (
        <Text className="dot-status">
          <Dot color={"#FB923C"} /> Disconnected
        </Text>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .dot-status {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Space Mono";
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #fbfbfb;
  }
`;

const Dot = styled.div`
  background-color: ${({ color }) => color};
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  border-radius: 8px;
`;
