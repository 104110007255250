import { Carousel, Embla } from "@mantine/carousel";
import { Flex, Image } from "@mantine/core";
import styled from "styled-components";

import number1 from "../assets/Numbers-1.svg";
import number2 from "../assets/Numbers-2.svg";
import number3 from "../assets/Numbers-3.svg";
import number4 from "../assets/Numbers-4.svg";
import number5 from "../assets/Numbers-5.svg";
import number6 from "../assets/Numbers-6.svg";

import arrowIcon from "../assets/Arrow.svg";

import classNames from "classnames";
import { useCallback, useState } from "react";
import GuideCarouselDetail1 from "./GuideCarouselDetail/GuideCarouselDetail1";
import GuideCarouselDetail2 from "./GuideCarouselDetail/GuideCarouselDetail2";
import GuideCarouselDetail3 from "./GuideCarouselDetail/GuideCarouselDetail3";
import GuideCarouselDetail4 from "./GuideCarouselDetail/GuideCarouselDetail4";
import GuideCarouselDetail5 from "./GuideCarouselDetail/GuideCarouselDetail5";
import GuideCarouselDetail6 from "./GuideCarouselDetail/GuideCarouselDetail6";

const STEP_TITLES = [
  "Download Fluvi Wallet",
  "Connect Fluvi Wallet",
  "Follow Twitter",
  "Receive LineaETH and Tokens",
  "Continue Voyage",
  "For Linea Voyage on Galxe",
];
const numberImages = [number1, number2, number3, number4, number5, number6];

export default function GuideCarousel() {
  const [currSlide, setCurrSlide] = useState(0);
  const [embla, setEmbla] = useState<Embla | null>(null);

  const onClickStepTitle = useCallback(
    (idx: number) => {
      if (!embla) return;
      setCurrSlide(idx);
      embla.scrollTo(idx);
    },
    [embla]
  );

  return (
    <Wrapper>
      <Flex className="step-title-container" direction={"column"}>
        {STEP_TITLES.map((elt, idx) => (
          <Flex
            onClick={() => onClickStepTitle(idx)}
            key={idx}
            style={{ cursor: "pointer" }}
            gap={8}
            className={classNames("step-title", { active: idx <= currSlide })}
          >
            <Image src={numberImages[idx]} width={32} />
            {elt}
          </Flex>
        ))}
      </Flex>
      <Flex
        className="step-detail-container"
        direction={"column"}
        align={"center"}
        justify={"center"}
      >
        <Carousel
          getEmblaApi={setEmbla}
          className="carousel-container"
          onSlideChange={(idx) => setCurrSlide(idx)}
          slideSize={"100%"}
          height={"100%"}
          maw={"100%"}
          previousControlIcon={
            <Image
              src={arrowIcon}
              width={48}
              style={{ transform: "rotate(180deg)" }}
            />
          }
          nextControlIcon={<Image src={arrowIcon} width={48} />}
          styles={{
            control:
              currSlide === 0
                ? { ":first-of-type": { display: "none" } }
                : currSlide === 5
                ? { ":last-of-type": { display: "none" } }
                : undefined,
          }}
        >
          <GuideCarouselDetail1 />
          <GuideCarouselDetail2 currSlide={currSlide} />
          <GuideCarouselDetail3 />
          <GuideCarouselDetail4 currSlide={currSlide} />
          <GuideCarouselDetail5 currSlide={currSlide} />
          <GuideCarouselDetail6 currSlide={currSlide} />
        </Carousel>
      </Flex>
    </Wrapper>
  );
}

const Wrapper = styled(Flex)`
  min-width: 700px;
  border: 4px solid #000000;
  border-radius: 12px;
  width: 100%;
  max-width: 930px;
  overflow: hidden;

  .step-title-container {
    background: #000000;
    padding: 24px;
    gap: 12px;
    flex: 0 0 50%;
    max-width: 50%;
    .step-title {
      &.active {
        opacity: 1;
      }
      font-family: "Satoshi";
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #ffffff;
      opacity: 0.3;
    }
  }
  .step-detail-container {
    flex: 0 0 50%;
    max-width: 50%;

    .carousel-container {
      height: 100%;

      .mantine-Carousel-controls {
        top: unset;
        bottom: 16px;
        padding: 0;
        justify-content: center;
        gap: 12px;

        .mantine-Carousel-control {
          border: unset;
          box-shadow: unset;
          opacity: unset;
          background: unset;
        }
      }
    }
  }
`;
