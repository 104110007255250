import React, { useEffect } from "react";
import styled from "styled-components";
import { Flex, Image } from "@mantine/core";
import fluviLogo from "./assets/FLUVI-LOGO.png";
import lineaLogo from "./assets/Linea-logo.png";
// import homeMainImage from "./assets/Home-Main.png";
// import signInIntroImage from "./assets/Sign-in-Intro.png";
import sponsorLogo1 from "./assets/Sponsor-Logo-1.png";
import sponsorLogo2 from "./assets/Sponsor-Logo-2.png";
import sponsorLogo3 from "./assets/Sponsor-Logo-3.png";
import sponsorLogo4 from "./assets/Sponsor-Logo.png";
import ethIcon from "./assets/ETH.svg";
import usdcIcon from "./assets/USDC.svg";
import usdtIcon from "./assets/USDT.svg";
import daiIcon from "./assets/DAI.svg";
import desktop from "./assets/Desktop.svg";
import GuideCarousel from "./components/GuideCarousel";
import {
  COMPOUND_LINK,
  PANCAKESWAP_LINK,
  SUSHISWAP_LINK,
  VELOCORE_LINK,
} from "./constants/externalLink";
import YoutubeWrapper from "./components/YoutubeWrapper";
import discordIcon from "./assets/DiscordLogo.svg";
import twitterIcon from "./assets/TwitterLogo.svg";
import globeIcon from "./assets/Globe.svg";
import arrowRight from "./assets/ArrowRight.svg";
import discordWhiteIcon from "./assets/DiscordLogo_white.svg";

import { useDisconnect } from "wagmi";

function App() {
  const isMobile =
    navigator.userAgent.match(
      /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson|LG|SAMSUNG|Samsung/i
    ) !== null;
  const { disconnect } = useDisconnect();

  useEffect(() => {
    localStorage.clear();
    disconnect();
  }, [disconnect]);

  if (isMobile) {
    return (
      <MobileWrapper>
        <Flex className="welcome-voyage">WELCOME TO LINEA VOYAGE</Flex>
        <Flex
          className="header-desc-container"
          direction={"column"}
          gap={32}
          align={"center"}
        >
          <Image src={fluviLogo} width={273} />
          <Image src={lineaLogo} width={246} />
        </Flex>
        <Flex className="header-desc" direction={"column"} align={"center"}>
          Enjoy a free starter pack of LineaETH and tokens. Transactions are
          free when you complete quests for the Blue chip partner protocols
          using the Fluvi wallet. All sponsored by Linea!
        </Flex>
        <Flex className="only-for-desktop">
          ONLY FOR DESKTOP
          <Image src={desktop} width={32} />
        </Flex>
        <a
          className="fluvi-inc"
          href={"https://fluviwallet.xyz"}
          target={"_blank"}
          rel="noreferrer"
        >
          © Fluvi Inc
        </a>
      </MobileWrapper>
    );
  }

  return (
    <Wrapper>
      <Flex
        className="header-desc-container"
        direction={"column"}
        gap={32}
        align={"center"}
      >
        <Flex className="welcome-voyage">WELCOME TO LINEA VOYAGE</Flex>
        <Image src={fluviLogo} width={273} />
        <Image src={lineaLogo} width={250} />
        <Flex className="header-desc" direction={"column"} align={"center"}>
          <Flex gap={8} align={"center"} style={{ whiteSpace: "nowrap" }}>
            Enjoy a <ColorText color={"#3539F3"}>free starter pack </ColorText>{" "}
            of LineaETH <Image src={ethIcon} width={36} /> tokens{" "}
            <Image src={usdcIcon} width={36} />
            <Image src={usdtIcon} width={36} />
            <Image src={daiIcon} width={36} />
          </Flex>
          <Flex>
            <ColorText color={"#D1FAE5"}>Transactions are free</ColorText> when
            you complete quests
            <br /> for the Blue chip partner protocols{" "}
            <ColorText color={"#D1FF39"}>using Fluvi wallet.</ColorText>
          </Flex>
          <Flex
            p={8}
            c="#FFFFFF"
            style={{
              fontFamily: "Satoshi",
              fontWeight: 700,
              fontSize: 20,
              lineHeight: "120%",
              opacity: 0.6,
            }}
          >
            <Flex gap={4}>
              Sponsored by
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://linea.build/", "_blank");
                }}
              >
                Linea
              </span>
              &
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://fluviwallet.xyz/", "_blank");
                }}
              >
                Fluvi
              </span>
              &
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://www.pimlico.io/", "_blank");
                }}
              >
                Pimlico
              </span>
            </Flex>
          </Flex>
          <Flex gap={16} align={"center"}>
            <a
              href={"https://discord.io/fluvi"}
              target="_blank"
              rel="noreferrer"
            >
              <Image src={discordIcon} width={36} />
            </a>
            <a
              href={"https://twitter.com/fluviweb3"}
              target="_blank"
              rel="noreferrer"
            >
              <Image src={twitterIcon} width={36} />
            </a>
            <a
              href={"https://fluviwallet.xyz/"}
              target="_blank"
              rel="noreferrer"
            >
              <Image src={globeIcon} width={36} />
            </a>
          </Flex>
        </Flex>
      </Flex>
      {/* <Flex gap={35} align={"center"}>
        <Image src={signInIntroImage} maw={375} width={"100%"} />
        <Image src={homeMainImage} maw={520} width={"100%"} />
      </Flex> */}
      <Flex className="youtube-container">
        <YoutubeWrapper
          width={"100%"}
          maxWidth={930}
          aspectRatio={578 / 930}
          videoId={"P-NVpVn5cg0"}
        />
      </Flex>

      <GuideCarousel />
      <Flex
        className="trouble-shooting-container"
        gap={24}
        direction={"column"}
        align={"center"}
      >
        <a
          href={"https://docs.fluviwallet.xyz/linea-voyage/troubleshooting-faq"}
          target="_blank"
          rel="noreferrer"
        >
          <Flex
            className="trouble-shooting-elt"
            gap={8}
            align={"center"}
            bg={"#A855F7"}
          >
            Troubleshooting / FAQ <Image src={arrowRight} width={32} />
          </Flex>
        </a>
        <a href={"https://discord.io/fluvi"} target="_blank" rel="noreferrer">
          <Flex
            className="trouble-shooting-elt"
            gap={8}
            align={"center"}
            bg={"#404EED"}
          >
            Connect Discord <Image src={discordWhiteIcon} width={36} />
          </Flex>
        </a>
      </Flex>
      <Flex
        gap={32}
        className="footer-container"
        direction={"column"}
        align={"center"}
      >
        <Flex className="footer-title" gap={16} align={"center"}>
          dApps with Gas Sponsorship
          <Flex className="footer-title-badge">GAS FREE</Flex>
        </Flex>
        <Flex gap={64} wrap={"wrap"}>
          <Flex direction={"column"} align={"center"} gap={32}>
            <a href={VELOCORE_LINK} target={"_blank"} rel="noreferrer">
              <Image width={344} src={sponsorLogo1} />
            </a>
            <a href={COMPOUND_LINK} target={"_blank"} rel="noreferrer">
              <Image width={288} src={sponsorLogo4} />
            </a>
          </Flex>
          <Flex direction={"column"} align={"center"} gap={32}>
            <a href={PANCAKESWAP_LINK} target={"_blank"} rel="noreferrer">
              <Image width={412} src={sponsorLogo3} />
            </a>
            <a
              // style={{
              //   opacity: 0.3,
              //   pointerEvents: "none",
              // }}
              href={SUSHISWAP_LINK}
              target={"_blank"}
              rel="noreferrer"
            >
              <Image width={347} src={sponsorLogo2} />
            </a>
          </Flex>
        </Flex>
      </Flex>
      <a
        className="fluvi-inc"
        href={"https://fluviwallet.xyz"}
        target={"_blank"}
        rel="noreferrer"
      >
        © Fluvi Inc.
      </a>
    </Wrapper>
  );
}

export default App;

const ColorText = styled.span`
  color: ${(props) => props.color};
  display: contents;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  overflow-x: hidden;
  overflow-y: overlay;

  .welcome-voyage {
    font-family: "Inter";
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    padding: 8px 24px;
    border: 4px solid #000000;
    border-radius: 100px;
  }
  .header-desc {
    font-family: "Satoshi";
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: #000000;
    gap: 8px;
  }

  .youtube-container {
    border-radius: 24px;
    overflow: hidden;
    width: 100%;
    max-width: 930px;
  }
  .trouble-shooting-container {
    a {
      text-decoration: none;
    }

    .trouble-shooting-elt {
      color: #fff;
      font-size: 32px;
      font-family: Satoshi;
      font-weight: 700;
      line-height: 140%;
      padding: 8px 16px;
      border-radius: 64px;
    }
  }
  .footer-container {
    .footer-title {
      font-family: "Satoshi";
      font-weight: 700;
      font-size: 32px;
      line-height: 140%;
      color: #000000;
      white-space: nowrap;
      color: #000;

      .footer-title-badge {
        white-space: nowrap;
        font-family: "Inter";
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
        padding: 8px 16px;
        background: #059669;
        border: 4px solid #059669;
        border-radius: 100px;
      }
    }
  }
  .fluvi-inc {
    font-family: "Satoshi";
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #000000;
  }
`;

const MobileWrapper = styled(Flex)`
  height: 100vh;
  padding: 32px 12px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  .welcome-voyage {
    white-space: nowrap;
    padding: 4px 24px;
    border-radius: 100px;
    border: 4px solid #000;
    color: #000;
    font-size: 20px;
    font-family: "Inter";
    font-weight: 700;
    line-height: 120%;
    width: fit-content;
  }
  .header-desc {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-family: "Satoshi";
    font-weight: 700;
    line-height: 120%;
    max-width: 284px;
  }

  .only-for-desktop {
    white-space: nowrap;
    color: #fff;
    font-size: 20px;
    font-family: "Inter";
    font-weight: 700;
    line-height: 28.8px;
    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: #000;
    width: fit-content;
  }

  .fluvi-inc {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-family: "Satoshi";
    font-weight: 700;
    line-height: 140%;
  }
`;
