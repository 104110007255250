import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface Props {
  from: "fluvi" | "metamask";
  fromAccount?: string;
}
export function useAlreadyMapped({ from, fromAccount }: Props) {
  const { data: mappedAccount, refetch } = useQuery(
    [`get${from}Mapping`, fromAccount],
    async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_FLUVI_API_URL}/api/address-mapping/${from}/${fromAccount}`,
        {
          headers: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
          },
        }
      );
      return res.data ? res.data : "unknown";
    },
    { enabled: !!fromAccount, retry: false }
  );
  if (!fromAccount || !mappedAccount) {
    return { mappedAccount: null, refetch };
  }
  return { mappedAccount, refetch };
}
