import CarouselButton from "../CarouselButton";
import { CarouselSlide } from "./CarouselSlide";
import styled from "styled-components";
import twitterIcon from "../../assets/Twitter_Logo.svg";

export default function GuideCarouselDetail3() {
  return (
    <Wrapper bg={"#5096FF"}>
      <CarouselButton
        icon={twitterIcon}
        onClick={(e) => {
          window.open("https://twitter.com/fluviweb3", "_blank");
        }}
      >
        Follow Twitter
      </CarouselButton>
    </Wrapper>
  );
}

const Wrapper = styled(CarouselSlide)``;
