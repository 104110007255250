import { Alert, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

interface Props {
  from: "fluvi" | "metamask";
  fromAccount?: string;
  isDeployed?: boolean;
  mappedAccount: string;
  sameConnected?: boolean;
}

export function AlertAlreadyMapped({
  from,
  fromAccount,
  isDeployed,
  mappedAccount,
  sameConnected,
}: Props) {
  if (fromAccount) {
    if (!isDeployed) {
      return (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="No transaction has been sent"
          color="red"
        >
          There should be at least one transaction using Fluvi Wallet
        </Alert>
      );
    } else if (sameConnected) {
      return (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="Same account connected"
          color="red"
        >
          Please disable the "Set as Default Wallet" option in the Fluvi wallet
          and refresh this page.
        </Alert>
      );
    }
  }

  if (!fromAccount || !mappedAccount) {
    return null;
  }

  return (
    <Alert
      icon={<IconAlertCircle size="1rem" />}
      title="You already done mapping task"
      color="red"
    >
      {from} account
      {mappedAccount === "unknown" ? (
        <>
          <Text>{fromAccount}</Text> is already used
        </>
      ) : (
        <>
          <Text>{fromAccount}</Text> has been successfully mapped to{" "}
          {<Text>{mappedAccount}</Text>}
        </>
      )}
      {/* {from === "metamask" ? "fluvi" : "metamask"} account
      {mappedAccount !== "unknown" && <Text>{mappedAccount}</Text>} */}
    </Alert>
  );
}
