import { Carousel } from "@mantine/carousel";
import styled from "styled-components";

export const CarouselSlide = styled(Carousel.Slide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* pointer-events: none; // carousel bug */
  gap: 12px;
  /* & button {
    pointer-events: auto;
  } */
`;
