import CarouselButton from "../CarouselButton";
import { CarouselSlide } from "./CarouselSlide";
import chromeIcon from "../../assets/GoogleChrome_Logo.svg";
import styled from "styled-components";

interface Props {
  currSlide?: number;
}
export default function GuideCarouselDetail1({ currSlide }: Props) {
  return (
    <Wrapper bg={"#D3C5FF"}>
      <CarouselButton
        icon={chromeIcon}
        onClick={(e) => {
          window.open(
            "https://chrome.google.com/webstore/detail/fluvi-wallet/mmmjbcfofconkannjonfmjjajpllddbg",
            "_blank"
          );
        }}
      >
        Install Fluvi Wallet For
      </CarouselButton>
    </Wrapper>
  );
}

const Wrapper = styled(CarouselSlide)``;
