import { Button } from "@mantine/core";
import { MouseEventHandler, ReactNode } from "react";

export function WrappedButton({
  onClick,
  disabled,
  loading,
  children,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  children: ReactNode;
}) {
  return (
    <Button
      fz={16}
      fw={700}
      ff={"Satoshi"}
      h={40}
      c={"#FAFAFA"}
      onClick={onClick}
      disabled={disabled}
      radius={64}
      styles={(theme) => ({
        root: {
          backgroundColor: "#6366F1",
          border: 0,
          "&:not([data-disabled])": theme.fn.hover({
            backgroundColor: theme.fn.darken("#6366F1", 0.05),
          }),
        },
      })}
      loading={loading}
    >
      {children}
    </Button>
  );
}
