import { hooks as metaMaskHooks, metaMask } from "./metaMask";
import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from "./walletConnectV2";

const connectorMap = {
  MetaMask: {
    connector: metaMask,
    hooks: metaMaskHooks,
  },
  WalletConnectV2: {
    connector: walletConnectV2,
    hooks: walletConnectV2Hooks,
  },
} as const;

export type ConnectorName = keyof typeof connectorMap;

export const getConnectorByName = (name: ConnectorName) =>
  connectorMap[name].connector;

export const getConnectorHooksByName = (name: ConnectorName) =>
  connectorMap[name].hooks;
