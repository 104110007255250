// import { Accounts } from "./Accounts";
import { ConnectButton } from "./ConnectButton";
import { Status } from "./Status";
import { useState } from "react";
import { ConnectorName } from "../connectors";
import Card from "./Card";
import metamaskIcon from "../assets/metamask-icon.png";
import fluviFIcon from "../assets/fluvi-f-icon.png";
import { useAccount, useConnect } from "wagmi";

interface Props {
  title: string;
  connectorName: ConnectorName;
  disabled?: boolean;
}

const iconMap = {
  MetaMask: metamaskIcon,
  WalletConnectV2: fluviFIcon,
};

export function WagmiConnectorCard({ title, connectorName, disabled }: Props) {
  const { isConnected } = useAccount();
  const { isLoading } = useConnect();

  const [error, setError] = useState(undefined);

  if (disabled) {
    return <Card title={title}></Card>;
  }

  return (
    <Card title={title} icon={iconMap[connectorName]}>
      <div>
        <Status isActivating={isLoading} isActive={isConnected} error={error} />
      </div>
      {/* <div>
        <Accounts accounts={accounts} />
      </div> */}
      <ConnectButton
        isActivating={isLoading}
        isActive={isConnected}
        error={error}
        setError={setError}
      />
    </Card>
  );
}
