import CarouselButton from "../CarouselButton";
import { CarouselSlide } from "./CarouselSlide";
import styled from "styled-components";
import { Flex, Image } from "@mantine/core";
import cardholderIcon from "../../assets/Cardholder.svg";
import howToConnectImage1 from "../../assets/how-to-connect-1.png";
import howToConnectImage2 from "../../assets/how-to-connect-2.png";
import pinGuide from "../../assets/Pin-Images.png";
import extensionIcon from "../../assets/extension.svg";
import pinIcon from "../../assets/extension-pin.svg";
import { useState } from "react";
import shiftBtn from "../../assets/shift-btn.svg";
import cmdBtn from "../../assets/command-btn.svg";
import eBtn from "../../assets/e-btn.svg";
import altBtn from "../../assets/alt-btn.svg";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useDisconnect } from "wagmi";

interface Props {
  currSlide?: number;
}
export default function GuideCarouselDetail2({ currSlide }: Props) {
  const [loadingConnect, setLoadingConnect] = useState(false);
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { isConnected: isActive, address: account } = useAccount();

  return (
    <Wrapper
      bg={"#5FC88F"}
      mah={currSlide === 1 ? undefined : 312}
      p={"24px 0 84px 0"}
    >
      <Flex className="pin-your-fluvi-wallet" direction={"column"} gap={12}>
        <Flex className="pin-your-fluvi-wallet-title">
          First, Pin Your Fluvi Wallet
        </Flex>
        <Flex direction={"column"} gap={8}>
          <Flex className="pin-your-fluvi-wallet-desc" align={"center"} gap={4}>
            1. Click <Image src={extensionIcon} width={16} /> In the Toolbar
          </Flex>
          <Flex className="pin-your-fluvi-wallet-desc" align={"center"} gap={4}>
            2. Click <Image src={pinIcon} width={16} /> Next to Fluvi Wallet
          </Flex>
          <Image src={pinGuide} width={400} />
          <Flex
            className="pin-your-fluvi-wallet-desc"
            align={"center"}
            gap={4}
            mt={16}
          >
            3. Click This Button
          </Flex>
        </Flex>
      </Flex>
      <CarouselButton
        icon={cardholderIcon}
        loading={loadingConnect}
        onClick={(e) => {
          if (!isActive) {
            setLoadingConnect(true);
            (async () => {
              try {
                await open();
              } catch (e) {
                localStorage.clear();
                console.error(e);
              } finally {
                setLoadingConnect(false);
              }
            })();
          } else {
            setLoadingConnect(true);
            localStorage.clear();
            disconnect();
            setLoadingConnect(false);
          }
        }}
      >
        <Flex gap={0} justify={"center"} align={"center"} direction={"column"}>
          {isActive ? "Disconnect" : "Connect Via WalletConnect"}
          <div style={{ fontSize: 14 }}>{account}</div>
        </Flex>
      </CarouselButton>
      <Flex direction={"column"} gap={12} align={"center"}>
        <span className="how-to-connect-header">How To Use WalletConnect</span>
        <Image src={howToConnectImage1} width={400} />
        <span className="how-to-connect-desc">Just Click</span>
        <Image src={howToConnectImage2} width={400} />
        <span
          className="how-to-connect-desc"
          style={{ alignSelf: "flex-start" }}
        >
          1. Open Fluvi Wallet.
          <br />
          2. Click WalletConnect Icon(Right conner)
          <br />
          3. Paste, then Connect
        </span>
        <Flex className="short-cut-guide" direction={"column"} gap={12}>
          <Flex>Open Fluvi Wallet with Shortcut</Flex>
          <Flex gap={8} align={"center"}>
            <Image src={shiftBtn} width={42} />
            +
            <Image src={cmdBtn} width={20} />
            +
            <Image src={eBtn} width={20} />
            (MAC)
          </Flex>
          <Flex gap={8} align={"center"}>
            <Image src={shiftBtn} width={42} />
            +
            <Image src={altBtn} width={27} />
            +
            <Image src={eBtn} width={20} />
            (Windows)
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
}

const Wrapper = styled(CarouselSlide)`
  gap: 24px;
  padding-top: 48px;
  .pin-your-fluvi-wallet {
    .pin-your-fluvi-wallet-title {
      color: #000;
      font-size: 24px;
      font-family: Satoshi;
      font-weight: 700;
      line-height: 33.6px;
    }
    .pin-your-fluvi-wallet-desc {
      color: #000;
      font-size: 14px;
      font-family: Inter;
      font-weight: 500;
      line-height: 140%;
    }
  }
  .how-to-connect-header {
    font-family: "Satoshi";
    font-weight: 700;
    font-size: 20px;
    line-height: 33.6px;
    color: #000000;
  }
  .how-to-connect-desc {
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #000000;
  }
  .short-cut-guide {
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
    line-height: 14px;
    color: #000;
    padding: 12px;
    border-radius: 12px;
    background: rgba(236, 236, 236, 0.7);
    width: 100%;
    max-width: 400px;
    margin-bottom: 12px;
  }
`;
