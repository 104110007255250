import { Alert, List, Loader, Modal, ThemeIcon } from "@mantine/core";
import styled from "styled-components";
import { IconAlertCircle, IconCheck } from "@tabler/icons-react";

interface Props {
  progress: Progress;
  error: any;
  onClose: () => void;
}
export enum Progress {
  DEFAULT = 0,
  SIGN_MM = 1,
  SIGN_WC = 2,
  SUBMIT = 3,
  DONE = 4,
  ERROR = 5,
}
export function ProgressModal({ progress, error, onClose }: Props) {
  const canClose = progress >= Progress.DONE;
  const title =
    progress === Progress.DONE
      ? "Done!"
      : progress === Progress.ERROR
      ? "Error"
      : undefined;
  return (
    <Modal
      opened={!!progress}
      onClose={canClose ? onClose : () => {}}
      centered
      size={"xs"}
      withCloseButton={canClose}
      title={title}
    >
      <Wrapper>
        {error ? (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Error!"
            color="red"
            style={{ overflowX: "auto" }}
          >
            {/* {JSON.stringify(error, Object.getOwnPropertyNames(error))} */}
            {error.message ?? "Oops, something went wrong"}
          </Alert>
        ) : (
          <List spacing={"xs"} size={"sm"} center>
            <List.Item
              icon={<ProgressIcon done={progress > Progress.SIGN_MM} />}
            >
              Sign MetaMask...
            </List.Item>
            {/* <List.Item
              icon={<ProgressIcon done={progress > Progress.SIGN_WC} />}
            >
              Sign FluviWallet...
            </List.Item> */}
            <List.Item
              icon={<ProgressIcon done={progress > Progress.SUBMIT} />}
            >
              Submit Connection...
            </List.Item>
          </List>
        )}
      </Wrapper>
    </Modal>
  );
}

function ProgressIcon({ done }: { done: boolean }) {
  return done ? (
    <ThemeIcon color="teal" size={24} radius="xl">
      <IconCheck size="1rem" />
    </ThemeIcon>
  ) : (
    <Loader size={24} />
  );
}

const Wrapper = styled.div`
  display: flex;
  overflow: overlay;
  overflow-x: auto;
  justify-content: center;
  align-items: center;
`;
