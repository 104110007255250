import type { Web3ReactHooks } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import { useCallback } from "react";
import { WrappedButton } from "./WrappedButton";
import { useWeb3Modal } from "@web3modal/react";
import { useDisconnect } from "wagmi";

export function ConnectButton({
  connector,
  isActivating,
  isActive,
  error,
  setError,
}: {
  connector?: MetaMask | WalletConnectV2;
  isActivating: ReturnType<Web3ReactHooks["useIsActivating"]>;
  isActive: ReturnType<Web3ReactHooks["useIsActive"]>;
  error: Error | undefined;
  setError: (error: Error | undefined) => void;
}) {
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const isMM = connector instanceof MetaMask;

  const connect = useCallback(async () => {
    try {
      if (isMM) {
        await connector.activate();
      } else {
        await open();
      }
      setError(undefined);
    } catch (e) {
      if (!isMM) {
        localStorage.clear();
      }
      setError(e);
    }
  }, [connector, isMM, open, setError]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {isActive ? (
        error ? (
          <WrappedButton
            onClick={(e) => {
              connect();
            }}
          >
            Try again
          </WrappedButton>
        ) : (
          <WrappedButton
            onClick={(e) => {
              try {
                if (isMM) {
                  if (connector?.deactivate) {
                    connector.deactivate();
                  } else {
                    connector.resetState();
                  }
                } else {
                  localStorage.clear();
                  disconnect();
                }
              } catch (e) {
                console.log(e);
              }
            }}
          >
            Disconnect
          </WrappedButton>
        )
      ) : (
        <WrappedButton
          onClick={(e) => {
            connect();
          }}
          disabled={isActivating}
        >
          {error ? "Try again" : "Connect"}
        </WrappedButton>
      )}
    </div>
  );
}
