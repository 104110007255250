import classNames from "classnames";
import React, { useState } from "react";
import YouTube, { YouTubePlayer } from "react-youtube";
import styled from "styled-components";

interface IYoutubeWrapperProps {
  id?: string;
  style?: React.CSSProperties;
  className?: string;
  videoId: string;
  width?: string | number;
  maxWidth?: string | number;
  height?: string | number;
  maxHeight?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  aspectRatio?: number; // height/width
  borderRadius?: number;
  thumbnailSrc?: string;
}
const YoutubeWrapper = ({
  id,
  videoId,
  width,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  height,
  style,
  aspectRatio,
  className,
  borderRadius,
  thumbnailSrc,
}: IYoutubeWrapperProps) => {
  // const [thumbnailOn, setThumbnailOn] = useState(true);
  const [, setYoutube] = useState<YouTubePlayer>();
  return (
    <Wrapper
      id={id}
      style={{
        ...style,
        width,
        maxWidth,
        maxHeight,
        minWidth,
        minHeight,
        height,
        borderRadius,
      }}
      className={classNames("YoutubeWrapper", className, {
        "apply-aspect-ratio": aspectRatio,
      })}
    >
      {/* {thumbnailSrc && thumbnailOn && (
        <ImgWrapper
          className={cx("thumbnail-img")}
          src={thumbnailSrc}
          onClick={() => {
            if (youtube) {
              youtube.playVideo();
              setThumbnailOn(false);
            }
          }}
        />
      )} */}
      <YouTube // handle playVideo() easily, using YouTube instead of raw iframe
        className={classNames("youtube")}
        iframeClassName={classNames("youtube-iframe")}
        videoId={videoId}
        opts={{ width: "100%", height: "100%" }}
        onReady={(e) => {
          setYoutube(e.target);
        }}
      />
      <div
        style={{
          paddingBottom: aspectRatio
            ? `calc(100% * (${aspectRatio}))`
            : undefined,
        }}
        className={classNames("padding-dummy")}
      />
    </Wrapper>
  );
};

export default YoutubeWrapper;

const Wrapper = styled.div`
  -webkit-transform: translate3d(0, 0, 0);
  overflow: hidden;

  .thumbnail-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: inherit;
    z-index: 1;
    cursor: pointer;
    background-color: #ffffff;
  }

  &.apply-aspect-ratio {
    position: relative;

    .padding-dummy {
      width: 100%;
    }

    .youtube {
      z-index: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      padding: inherit;
    }
  }
`;
