import React from "react";
import { CarouselSlide } from "./CarouselSlide";
import styled from "styled-components";
import { Flex, Image } from "@mantine/core";
import sponsorLogo1 from "../../assets/Sponsor-Logo-1.png";
import sponsorLogo2 from "../../assets/Sponsor-Logo-2.png";
import sponsorLogo3 from "../../assets/Sponsor-Logo-3.png";
import sponsorLogo4 from "../../assets/Sponsor-Logo.png";
import setAsDefaultImage from "../../assets/set-as-default.png";
import walletConnectIcon from "../../assets/Walletconnect.svg";
import {
  COMPOUND_LINK,
  PANCAKESWAP_LINK,
  SUSHISWAP_LINK,
  VELOCORE_LINK,
} from "../../constants/externalLink";

interface Props {
  currSlide?: number;
}
export default function GuideCarouselDetail5({ currSlide }: Props) {
  return (
    <Wrapper
      bg={"#A5B4FC"}
      mah={
        currSlide === 4
          ? undefined
          : currSlide === 3
          ? 1645.09
          : currSlide === 5
          ? 912.75
          : 312
      }
      p={"24px 0 84px 0"}
    >
      <Flex className="using-dapp-title" gap={8} p={"16px 24px"}>
        Use dApps{" "}
        <Flex className="gas-free-badge-small" align={"center"}>
          GAS FREE
        </Flex>
      </Flex>
      <Flex direction={"column"} gap={24} align={"center"}>
        <Flex
          className="link-container"
          direction={"column"}
          gap={24}
          p={"24px 12px"}
          align={"center"}
          w={400}
        >
          <Flex direction={"column"} gap={8} align={"center"}>
            <Flex gap={8} align={"center"} justify={"space-between"}>
              <a href={COMPOUND_LINK} target={"_blank"} rel="noreferrer">
                <Image width={150} src={sponsorLogo4} />
              </a>
              <div className="set-as-default-wallet">Set as Default Wallet</div>
            </Flex>
            <Flex gap={8} align={"center"} justify={"space-between"}>
              <a href={SUSHISWAP_LINK} target={"_blank"} rel="noreferrer">
                <Image width={148} src={sponsorLogo2} />
              </a>
              <div className="set-as-default-wallet">Set as Default Wallet</div>
            </Flex>
          </Flex>
          <Flex
            className="fluvi-wallet-setting"
            direction={"column"}
            gap={8}
            align={"center"}
          >
            Fluvi Wallet Setting
            <Image src={setAsDefaultImage} width={320} />
          </Flex>
        </Flex>
        <Flex
          className="link-container"
          direction={"column"}
          gap={8}
          p={"24px 12px"}
          align={"center"}
          w={400}
        >
          <Flex gap={8} align={"center"}>
            <Image src={walletConnectIcon} width={24} />
            <div className="via-wallet-connect-desc">
              Connect via Wallet Connect or Turn on Set as Default Wallet
            </div>
          </Flex>
          <a href={VELOCORE_LINK} target={"_blank"} rel="noreferrer">
            <Image width={172} src={sponsorLogo1} />
          </a>
          <a href={PANCAKESWAP_LINK} target={"_blank"} rel="noreferrer">
            <Image width={206} src={sponsorLogo3} />
          </a>
        </Flex>
      </Flex>
    </Wrapper>
  );
}

const Wrapper = styled(CarouselSlide)`
  .using-dapp-title {
    font-family: "Satoshi";
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
  }
  .gas-free-badge-small {
    padding: 2px 12px;
    background: #059669;
    border: 4px solid #059669;
    border-radius: 64px;
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
  }
  .link-container {
    border-radius: 12px;
    background: rgba(236, 236, 236, 0.7);

    .set-as-default-wallet {
      color: #000;
      font-size: 14px;
      font-family: Inter;
      font-weight: 500;
      line-height: 14px;
      border-radius: 4px;
      border: 1px solid #000;
      padding: 4px;
      height: fit-content;
    }
    .fluvi-wallet-setting {
      color: #000;
      font-size: 14px;
      font-family: Inter;
      font-weight: 500;
      line-height: 14px;
    }
    .via-wallet-connect-desc {
      color: #000;
      font-size: 14px;
      font-family: Inter;
      font-weight: 500;
      line-height: 120%;
      max-width: 207px;
    }
  }
`;
