// import { Accounts } from "./Accounts";
import { ConnectButton } from "./ConnectButton";
import { Status } from "./Status";
import { useEffect, useState } from "react";
import {
  ConnectorName,
  getConnectorByName,
  getConnectorHooksByName,
} from "../connectors";
import Card from "./Card";
import metamaskIcon from "../assets/metamask-icon.png";
import fluviFIcon from "../assets/fluvi-f-icon.png";

interface Props {
  title: string;
  connectorName: ConnectorName;
  disabled?: boolean;
}

const iconMap = {
  MetaMask: metamaskIcon,
  WalletConnectV2: fluviFIcon,
};

export function W3RConnectorCard({ title, connectorName, disabled }: Props) {
  const connector = getConnectorByName(connectorName);
  const { useIsActivating, useIsActive } =
    getConnectorHooksByName(connectorName);

  const isActivating = useIsActivating();
  const isActive = useIsActive();

  const [error, setError] = useState(undefined);

  useEffect(() => {
    connector.connectEagerly().catch((error) => {
      console.debug(`Failed to connect eagerly to ${connectorName}`, error);
    });
  }, [connector, connectorName]);

  if (disabled) {
    return <Card title={title}></Card>;
  }

  return (
    <Card title={title} icon={iconMap[connectorName]}>
      <div>
        <Status isActivating={isActivating} isActive={isActive} error={error} />
      </div>
      {/* <div>
        <Accounts accounts={accounts} />
      </div> */}
      <ConnectButton
        connector={connector}
        isActivating={isActivating}
        isActive={isActive}
        error={error}
        setError={setError}
      />
    </Card>
  );
}
