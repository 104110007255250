import { Overlay, Title, Image } from "@mantine/core";
import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  title: string;
  disabled?: boolean;
  children?: ReactNode;
  icon?: string;
}
function Card({ title, disabled, children, icon }: Props) {
  return (
    <Wrapper className={disabled ? "disabled" : undefined}>
      <Title className={"card-title"}>
        {icon && <Image src={icon} width={24} height={24} />}
        {title}
      </Title>
      {children}
      {disabled && <Overlay color="#000" opacity={0.2} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  overflow: hidden;

  padding: 24px;
  gap: 12px;

  /* Indigo/indigo.100 */

  border-radius: 12px;

  .card-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Satoshi";
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #fafafa;
  }

  background: #000000;
`;

export default Card;
