import { Button, Image } from "@mantine/core";
import { ReactNode } from "react";

interface Props {
  icon?: string;
  disabled?: boolean;
  onClick?: (e) => void;
  children?: ReactNode;
  loading?: boolean;
}
export default function CarouselButton({
  icon,
  onClick,
  disabled,
  children,
  loading,
}: Props) {
  return (
    <Button
      loaderProps={{
        color: "gray",
        size: "sm",
      }}
      loading={loading}
      onClick={onClick}
      disabled={disabled}
      styles={{
        root: {
          height: "unset",
          display: "flex",
          alignItems: "center",
          padding: "4px 20px",
          border: "4px solid #000000",
          borderRadius: 64,
          background: "unset",
          overflow: "hidden",
          ":disabled": {
            opacity: 0.5,
            background: "unset",
            border: "4px solid #000000",
          },
          ":hover": {
            background: "#FFFFFF",
          },
          "::before": {
            zIndex: 1,
          },
        },
        label: {
          gap: 8,
          fontFamily: "Satoshi",
          fontWeight: 700,
          fontSize: 24,
          lineHeight: "34px",
          color: "#000000",
        },
      }}
    >
      {children}
      {icon && <Image src={icon} width={36} />}
    </Button>
  );
}
