import { initializeConnector } from "@web3-react/core";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";

export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: process.env.REACT_APP_WC_PROJECT_ID,
        chains: [59140, 80001, 5],
        rpcMap: {
          59140: "https://rpc.goerli.linea.build",
          80001: "https://rpc.ankr.com/polygon_mumbai",
          5: "https://rpc.ankr.com/eth_goerli",
        },
        showQrModal: true,
      },
    })
);
