import { getConnectorHooksByName } from "../connectors";
import Card from "./Card";
import { Image } from "@mantine/core";
import { Progress } from "./ProgressModal";
import axios from "axios";
import { Text } from "@mantine/core";
import { WrappedButton } from "./WrappedButton";
import styled from "styled-components";
import metamaskIcon from "../assets/metamask-icon.png";
import fluviFIcon from "../assets/fluvi-f-icon.png";
import { useAccount } from "wagmi";

interface Props {
  setProgress: (progress: Progress) => void;
  setError: (error: any) => void;
  disabled?: boolean;
  loading?: boolean;
  onSuccess?: () => void;
}

export function SignCard({
  onSuccess,
  setProgress,
  setError,
  disabled,
  loading,
}: Props) {
  const mmHooks = getConnectorHooksByName("MetaMask");
  const [mmActive, mmAccount] = [mmHooks.useIsActive(), mmHooks.useAccount()];
  const mmSigner = mmHooks.useProvider()?.getSigner();
  const { isConnected, address: wcAccount } = useAccount();
  // const { data: walletClient } = useWalletClient();

  const wcActive = isConnected;

  const _disabled = !mmActive || !wcActive;

  const sign = async (e) => {
    try {
      setProgress(Progress.SIGN_MM);
      const mmSignature = await mmSigner?.signMessage(
        `address:${wcAccount?.toLowerCase()}`
      );

      // setProgress(Progress.SIGN_WC);
      // const wcSignature = await walletClient?.signMessage({
      //   account: wcAccount,
      //   message: `address:${mmAccount?.toLowerCase()}`,
      // });

      setProgress(Progress.SUBMIT);
      await axios.put(
        `${process.env.REACT_APP_FLUVI_API_URL}/api/address-mapping`,
        {
          metaMaskSignature: mmSignature,
          // fluviSignature: wcSignature,
          metaMaskAddress: mmAccount?.toLowerCase(),
          fluviAddress: wcAccount?.toLowerCase(),
        }
      );
      await onSuccess?.();
      setProgress(Progress.DONE);
    } catch (e) {
      setError({ message: e?.response?.data, err: e });
      console.error(e);
      setProgress(Progress.ERROR);
    }
  };
  return (
    <Card title={"Submit your connection"}>
      <AccountRow type={"Metamask"} icon={metamaskIcon} address={mmAccount} />
      <AccountRow type={"Fluvi Wallet"} icon={fluviFIcon} address={wcAccount} />
      <WrappedButton
        onClick={sign}
        disabled={disabled || _disabled}
        loading={loading}
      >
        Submit
      </WrappedButton>
    </Card>
  );
}

function AccountRow({
  type,
  icon,
  address,
}: {
  type: string;
  icon: string;
  address: string;
}) {
  return (
    <AccountRowWrapper>
      <Image src={icon} width={24} />
      <div>
        <Text className={"account-row-text"} fz={14} c={"#FBFBFB"}>
          Your {type} Account Address is
        </Text>
        <Text
          className={"account-row-text address"}
          fz={12}
          c={address ? "#34D399" : "red"}
          lh={"30px"}
          fw={600}
        >
          {address ? address : "Not Connected"}
        </Text>
      </div>
    </AccountRowWrapper>
  );
}

const AccountRowWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  .account-row-text {
    font-family: "Space Mono";
    font-size: 14px;
    line-height: 140%;
    word-break: break-all;

    &.address {
      font-size: 12px;
    }
  }
`;

// function AccountSelect({
//   selectedAccount,
//   switchAccount,
//   accounts,
// }: {
//   selectedAccount: string;
//   switchAccount: (account: string) => void;
//   accounts: string[];
// }) {
//   return (
//     <div style={{ marginTop: 8 }}>
//       <NativeSelect
//         value={selectedAccount}
//         data={accounts}
//         onChange={(event) => {
//           switchAccount(event.target.value);
//         }}
//       />
//     </div>
//   );
// }
